import React, { useState, Fragment } from 'react'
import Badge from '../Badge/Badge';
import List from '../List/List'


import closeSvg from '../../assets/img/exit.svg'

const AddButtonList = ({ colors, addToList }) => {
    const [visiblePopUp, setVisiblePopUp] = useState(false);
    const [colorBadge, setColorBadge] = useState(colors[0].id);
    const [inputValue, setInputValue] = useState(""); 

    const addList = () => {
        if (!inputValue) {
            alert("Введите название задачи");
            return;
        }

        addToList({ "name": inputValue, "colorId": colorBadge});
        setVisiblePopUp(false);
        setInputValue("");
        setColorBadge(colors[0].id)
        
    };

    return (
        <Fragment>
                <List 
                onClick={() => setVisiblePopUp(!visiblePopUp)}
                items={[{    
                    className: "list__add-button",
                    name: "Добавить здачу",
                    icon: (<svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 1V11" stroke="#868686" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 6H11" stroke="#868686" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>)
                }]} />

                {visiblePopUp && <div className="list__add-popup">
                    <img src={closeSvg} alt="Close" onClick={() => setVisiblePopUp(false)} />

                    <input value={inputValue} 
                        onChange={e => setInputValue(e.target.value)} 
                        type="text"  
                        placeholder="Название папки"/>

                    <div className="list__add-popup-colors">
                        {colors.map((color) => (
                            <Badge 
                                onClick={() => setColorBadge(color.id)} 
                                key={color.id} 
                                color={color.name}
                                className={colorBadge === color.id && 'active'}
                            />) 
                        )}
                    </div>
                    <button onClick={addList}>Добавить</button>
                </div>}
        </Fragment>
    )
}

export default AddButtonList;

