import React from 'react';
import './list.scss'
import classNames from "classnames";
import Badge from '../Badge/Badge';

import removeSvg from '../../assets/img/delete.svg';


const List = ({ items, isRemovable, onClick, onRemove, activeItem, onClickItem }) => {


  const removeList = (item) => {
      if (window.confirm("Удалить список?")) {
        onRemove(item)
      }
  }


  return (
    <ul onClick={onClick} className="list">

      {items.map((item, index) => (
      
        <li
          onClick={() => { onClickItem && onClickItem(item) }}
          key={index}
          className={classNames(item.className, { active: item.active
              ? item.active
              : activeItem && activeItem.id === item.id })}>
          <div>
             <i>
              {item.icon ? 
              item.icon :(<Badge color={item.color.name} />)}
            </i>
            <span>{item.name} { item.tasks && item.tasks.length > 0 && `(${item.tasks.length})` }</span>
          </div>
         
          {isRemovable && <img src={removeSvg} alt="Remove" onClick={() => removeList(item)} />}
        </li>))}
      
    </ul> 
  );
} 

export default List;