import axios from 'axios';
import {React, Fragment, useState} from 'react'
import "./Auth.scss";


export default function Auth({authLogin}) {

    // Input
    const [register, setRegister] = useState(false);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");

    const [warning, setWarning] = useState("");
    const [warningVisible, setwarningVisible] = useState(false);



    const clearInput = () => {
        setLogin("");
        setPassword("");
        setConfPassword("");
        setWarning("");
    }

    const changeRegister = () => {
        clearInput();
        setRegister(!register);
    }

    const onRegister = () => {
        setwarningVisible(false);
        if (!login) {
            setwarningVisible(true);
            return setWarning("Введите логин")

        }
        if (!password) {
            setwarningVisible(true);
            return setWarning("Введите пароль")
        }
        if (password !== confPassword) {
            setwarningVisible(true);
            return setWarning("Пароли не совпадают")
        }
        
        axios.post("https://react-todo-api1.herokuapp.com/api/add-user", { "login": login, "password": password })
            .then(changeRegister)
            .catch((error) => { setwarningVisible(true); setWarning(String(error.response.data.message));})
    }

    const onLogin = () => {
        setwarningVisible(false);
        if (!login) {
            setwarningVisible(true);
            return setWarning("Введите логин")

        }
        if (!password) {
            setwarningVisible(true);
            return setWarning("Введите пароль")
        }

        axios.get("https://react-todo-api1.herokuapp.com/api/login", { params: { "login": login, "password": password } })
            .then(({ data }) => { authLogin(data)})
            .catch((error) => { setwarningVisible(true); setWarning(String(error.response.data.message)) })
    }


    return (
            
        <div className="auth">
            <div className="wrapper">
            {!register ?
                <Fragment>
                    <div className="auth__title">Авторизоваться</div>
                    <div className="auth__field">
                        <input
                            type="text"
                            placeholder="Login"
                            value={login}
                            onChange={e => setLogin(e.target.value)}/>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="auth__button">
                        <button onClick={onLogin}>Авторизоваться</button>
                        <button className="grey" onClick={changeRegister}>Создать аккаунт</button>
                    </div>
                </Fragment> :
                
                <Fragment>
                    <div className="auth__title">Зарегистрироваться</div>
                    <div className="auth__field">
                        <input
                            type="text"
                            placeholder="Login"
                            value={login}
                            onChange={e => setLogin(e.target.value)} />
                        
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Return Password"
                            value={confPassword}
                            onChange={e => setConfPassword(e.target.value)}/>
                    </div>
                    <div className="auth__button">
                        <button onClick={() => onRegister()}>Зарегистрироваться</button>
                        <button className="grey" onClick={changeRegister}>Авторизоваться</button>
                    </div>
                </Fragment>}
            
                <div className={warningVisible ? "auth__warning active" : "auth__warning"}>
                    <p>{warning}</p>
                </div>
                </div>
            </div>
                
            
        
        
    )
}
