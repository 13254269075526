import { React, useState } from 'react';
import axios from 'axios';
import plusSvg from '../../assets/img/plus.svg';

export default function AddTaskForm({list, onAddTask}) {
    const [visibleForm, setVisibleForm] = useState(false);
    const [inputValue, setInputValue] = useState("");


    const closeWindow = () => {
        setVisibleForm(!visibleForm);
        setInputValue("");
    }

    const addTask = () => {
        const obj = {
            "listId": list.id,
            "text": inputValue
        }
        axios
            .post("https://react-todo-api1.herokuapp.com/api/add-tasks", obj)
            .then(({ data }) => {
                closeWindow();
                onAddTask(data);})
            .catch((e) => { console.error(e); })
        
    }

    return (
        <div className="tasks__form">
            {!visibleForm ? <div onClick={closeWindow} className="tasks__form-button">
                <img src={plusSvg} alt="+" />
                <span>Новая задача</span>
            </div>
            : <div className="tasks__form-block">
                    <input
                        type="text"
                        placeholder="Текст задачи"
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}/>
                    <button onClick={addTask} className="green">Добавить</button>
                    <button onClick={closeWindow} className="grey">Отмена</button>
            </div>}
        </div>
    )
}
