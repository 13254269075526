import { React } from 'react'
import './Tasks.scss';

import editSvg from '../../assets/img/pen.svg';
import trashSvg from '../../assets/img/trash.svg';

import Placeholder from '../Placeholder/placeholder';
import AddTaskForm from './AddTaskForm';


export default function Tasks({ tasks, onEditTitle, onAddTask, onDeleteTask, onCompleteTask }) {

    const editTitle = () => {
        const newTitle = window.prompt("Введите новое названия:", tasks.name);
        if (newTitle) {
            onEditTitle(tasks.id, newTitle)
        }
    }

    if (Array.isArray(tasks)) { 
        return (
        <div className="tasks">
            {tasks.map((item) => (
                <div className="task" key={item.id}>
                    <h2 style={{color: item.color.hex}} className="tasks__title">{item.name}
                        <img
                            onClick={() => {
                                const newTitle = window.prompt("Введите новое названия:", item.name);
                                newTitle && onEditTitle(item.id, newTitle);
                            }} src={editSvg} alt="Edit" /></h2>
                    
                    {Object.keys(item.tasks).length !== 0 ?
                        item.tasks.map((item) => (
                            <div key={item.id} className="tasks__item">
                                <div className="checkbox">
                                    <input onChange={() => onCompleteTask(item)} type="checkbox" id={`task-${item.id}`} checked={item.completed} />
                                    <label htmlFor={`task-${item.id}`}>
                                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.29999 1.20001L3.79999 6.70001L1.29999 4.20001"
                                            stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </label>

                                    {/* <input readOnly={true} type="text" value={item.text} /> */}
                                    <p>{item.text}</p>
                                    <img onClick={() => onDeleteTask(item.id)} src={trashSvg} alt="Delete" />
                                </div>
                            </div>
                        ))
                        : <Placeholder/>
                    }
                    
                    <AddTaskForm key={item.id} list={item} onAddTask={onAddTask}/>
                    

                </div>
            ))}
            
        </div>)
    } else {
        return (
            <div className="tasks">
                <div className="task">
                    <h2 style={{color: tasks.color.hex}} className="tasks__title">{tasks.name} <img onClick={editTitle} src={editSvg} alt="Edit" /></h2>
                    {Object.keys(tasks.tasks).length !== 0 ?
                        tasks.tasks.map((item) => (
                            <div key={item.id} className="tasks__item">
                                <div className="checkbox">
                                    <input onChange={() => onCompleteTask(item)} type="checkbox" id={`task-${item.id}`} checked={item.completed} />
                                    <label htmlFor={`task-${item.id}`}>
                                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.29999 1.20001L3.79999 6.70001L1.29999 4.20001"
                                            stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </label>
                                    {/* <input readOnly={true} type="text" value={item.text} /> */}
                                    <p>{item.text}</p>
                                    <img onClick={() => onDeleteTask(item.id)} src={trashSvg} alt="Delete" />
                                </div>
                            </div>
                        ))
                        : <Placeholder />
                    }
                    
                    <AddTaskForm key={tasks.id} list={tasks} onAddTask={onAddTask}/>

                </div>
            
        </div>
        )
    }
    
}
