import React from 'react'
import './placeholder.scss';

export default function placeholder() {
    return (
        <div className="placeholder">
            <h2>Задачи отсутствуют</h2>
        </div>
    )
}
